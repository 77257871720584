import React, { useEffect } from 'react';

const About = () => {
	useEffect(() => {
			window.scrollTo(0,0)
	}, [])
	return (
		<div className='about'>
			<div className='section-header'>
				<h2>About Us</h2>
			</div>
			<div className='about-main'>
				<section className='mission'>
					<h3>Our Mission and Story</h3>
					<p>Fundraise the $50,000 requirement before December 2024, to deploy the Ken Johnston Warrior Fund 501(c)(3) as an endowment where 100% of proceeds go directly to Peritoneal Carcinomatosis research, outreach, and education with Wake Forest University Baptist Health. Together, we can make a difference!</p>
				</section>
				<p>We have established the Ken Johnston Warrior Fund with Wake Forest University Baptist Health and pledged to raise $50,000 before December 2024. Now, we need your help to meet our goal!</p>
				<section className='what-happens'>
					<h3>What happens once we raise $50,000?</h3>
					<ul>
						<li><p>The Fund deploys as an endowment with a principal of $50,000</p></li>
						<li><p>Once deployed, the Fund begins accruing interest</p></li>
						<li><p>After the first full fiscal year of annual interest, the interest is used to fund research, education, and outreach for Peritoneal Carcinomatosis while the principal carries into the next fiscal year</p></li>
						<li><p>Each subsequent fiscal year, the annual interest is applied to research and we will share research the Fund is applied to</p></li>
					</ul>
				</section>
				{/* <section className='about-faq'>
					<h3>Have questions about our fund? See our <a href='/faq'>FAQ</a></h3>
				</section> */}
			</div>
		</div>
	)
}

export default About