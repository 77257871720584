import React, { useEffect } from 'react';

const PMP = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className='pmp'>
			<div className='section-header'>
				<h2>What is PMP?</h2>
			</div>
			<div className='pmp-main'>
				<p>Peritoneal cancer affects the thin layer of cells called the “peritoneum” that covers all organs and the lining of the abdominal cavity.</p>
				<p>Until recently, peritoneal cancer has been regarded as very rare, however we are learning it is not as rare as once believed. Lack of PMP education, adequate resources and research, and available treatments across the medical field all contribute to the grim prognoses patients often receive. However, our mission to deploy the Ken Johnston Warrior Fund where 100% of proceeds go directly to peritoneal cancer research, education, and treatments will make a positive impact in the future of patients' lives.</p>
				<div className='pmp-fast-facts'>
					<p style={{ fontWeight: 600, textAlign: 'center' }}>PMP FAST FACTS</p>
					<p>Approximately 10% of colon cancer patients will develop peritoneal cancer.</p>
					<p>Approximately 30% of gastric cancer patients will develop peritoneal cancer.</p>
					<p>The most rare forms, Malignant Mesothelioma and PMP, affect around 1 to 3 per 1,000,000 patients annually.</p>
					<p>The survival rate is approximately 6 weeks to 6 months when left untreated or when diagnosed too late.</p>
					<p>Because it can often go undiagnosed without proper care, peritoneal cancer has garnered a reputation as a “silent killer” (which we hope to help end).</p>
				</div>
				{/* <div className='pmp-faq-container'>
					<div className='pmp-faq-item' id='1'
					>
						<h3>3 Origins</h3>
							<ol>
								<li>Malignant Mesothelioma, a very rare type where the cancer starts in the mesothelial cells lining the peritoneum.</li>
								<li>Pseudomyxoma peritonei or PMP, is a cancer of “mucus tumors” which typically start in the appendix. As the mucus tumors grow it can cause the appendix to burst and spread the cancerous cells throughout the abdominal cavity. The mucus will continue to grow and fill the abdomen.</li>
								<li>The most common is when cells from a primary tumor elsewhere in the body, most commonly the stomach, colon, ovaries, or anywhere, travel and invade the peritoneum. They grow small nodules there and eventually the organs where they can cause obstructions.</li>
							</ol>
					</div>

					<div className='pmp-faq-item'
					>
						<h3>Why was PMP believed to be so rare?</h3>
\                                <div>
								<p>often goes undetected on radiological scans</p>
								<p>Also, because it may be caused from a primary tumor, it can often be mistaken for a different disease, as it can often be asymptomatic.</p>
							</div>
					</div>

					<p>Fortunately, due to heightened awareness, there has been an increase in reported incidences because symptoms are more easily recognizable through primary cancers. Still, there are many cases that are never found until they are discovered in autopsies or during surgeries in which surgeons are attempting to remove a primary tumor and then “accidentally” find peritoneal cancer tumors.</p>
				</div> */}
			</div>
		</div>
	)
}

export default PMP