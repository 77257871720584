import React, { useEffect } from 'react'

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	return (
		<div className='contact'>
			<h2>Contact</h2>
			<form className='contact-form'>
				<div className="contact-item">
					<label>Name</label>
					<input type="text" className="contact-input" id="contact-name"></input>
				</div>
				<div className="contact-item">
					<label>Email</label>
					<input type="text" className="contact-input" id="contact-email"></input>
				</div>
				<div className="contact-item">
					<label>Reason for Contacting</label>
					<input type="text" className="contact-input" id="contact-reason"></input>
				</div>
			</form>
		</div>
	)
}

export default Contact