import React, { useEffect } from 'react'

const Faq = () => {
	useEffect(() => {
			window.scrollTo(0, 0)
	})
	return (
		<div className='faq'>
			<h2>FAQ</h2>
			<div className='faq-container'>
				<i className="fas fa-wrench"></i>
					This section is currently under construction, please check back soon!

				{/* <h3>What is an endowment?</h3>
				<h3>Why Wake Forest University?</h3> */}
			</div>
		</div>
	)
}

export default Faq