import React, { useEffect } from 'react';
import QR from '../../Images/qr-code.png'

const Donate = () => {
	useEffect(() => {
			window.scrollTo(0, 0)
	}, [])
	return (
		<div className='donate'>
			<div className='section-header'>
				<h2>Ways to Donate</h2>
			</div>
			<div className='donate-container'>
				<div className='donate-item'>
					<h3>Directly to Wake Forest University</h3>
					<h4 style={{"color": "#FF3300"}}>*In the <em style={{"color": "#FF3300"}}>'Direct my gift to'</em> dropdown, select <em style={{"color": "#FF3300"}}>'Ken Johnston Warrior Fund for Peritoneal Carcinomatosis Research'</em> under the Cancer title.</h4>
					<button className='donate-btn'
						onClick={() => window.open('https://give.wakehealth.edu/site/Donation2;jsessionid=00000000.app20014a?1400.donation=form1&df_id=1400&mfc_pref=T&NONCE_TOKEN=BB4D9753F1CEAD8D7FF5286EDE80F85F')}>
						DONATE HERE
					</button>
					<p>*100% of all donations go directly to Ken Johnston Warrior Fund</p>
				</div>
				<div className='donate-item'>
					<h3>PayPal</h3>
					<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
						<input type="hidden" name="cmd" value="_donations" />
						<input type="hidden" name="business" value="kenjohnstonwarriorfund@gmail.com" />
						<input type="hidden" name="item_name" value="Ken Johnston Warrior Fund for Peritoneal Carcinomatosis Research" />
						<input type="hidden" name="currency_code" value="USD" />
						<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
						<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
					</form>
					<img src={QR} width='150px' alt="QR code for paypal donations" style={{ margin: '48px' }} />
				</div>
			</div>
		</div>
	)
}

export default Donate