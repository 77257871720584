import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Nav from '../Nav/Nav';
import LandingPage from '../LandingPage/LandingPage';
import Ken from '../Ken/Ken';
import Pmp from '../PMP/PMP';
import About from '../About/About';
import Faq from '../Faq/Faq';
import Footer from '../Footer/Footer';
import GetInvolved from '../GetInvolved/GetInvolved';
import Fundraisers from '../Fundraisers/Fundraisers';
import Donate from '../Donate/Donate';
import Contact from '../Contact/Contact';

import './App.css';

const App = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div className='App'>
      <Nav />
      <div className='app-main'>
        <Route
          exact path='/'
          component={LandingPage}
        />
        <Route
          path='/ken'
          component={Ken}
        />
        <Route
          path='/pmp'
          component={Pmp}
        />
        <Route
          path='/about'
          component={About}
        />
        <Route
          path='/faq'
          component={Faq}
        />
        <Route
          path='/get-involved'
          component={GetInvolved}
        />
        <Route
          path='/fundraisers'
          component={Fundraisers}
        />
        <Route
          path='/donate'
          component={Donate}
        />
        <Route
          path='/contact'
          component={Contact}
        />
      </div>
      <Footer />
    </div>
  );
}

export default App