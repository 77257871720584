import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import cardSample from '../../Images/card-fundraiser.jpg'

const Fundraisers = () => {
	useEffect(() => {
		window.scrollTo(0,0)
	})
	return (
		<div className='fundraisers'>
			<div className='section-header'>
				<h2>Fundraisers</h2>
			</div>
			<div className='fundraiser-container'>
				<div className='fundraiser-item'>
					<ul>
						{/* <li>
							<h3>Virtual 5K</h3>
							<h4>How it works:</h4>
							<p>1. <Link to="/donate">Sign up and please donate</Link> at least $5 to join. (You may also get sponsors to sponsor your 5K as well, just share this page with them!) <small><i>*100% of donations go to the Ken Johnston Warrior Fund.</i></small></p>
							<p>2. Make your own "course" to walk/hike/run. It can be on a treadmill, outdoor trail, etc. as long as the distance is 3.1 miles!</p>
							<p>3. Complete your 5K by <strong>Mon. May 31st</strong> and <a href="mailto:kenjohnstonwarriorfund@gmail.com?Subject=Virtual%205K%20Entry" target="_blank" rel="noopener noreferrer">submit</a> a screenshot or photo of your time, distance, and which activity you did!</p>
						</li> */}
						<li>
							<h3>Greeting Card Sale</h3>
							<h4>How it works:</h4>
							<p>1. <a href="mailto:kenjohnstonwarriorfund@gmail.com?Subject=Greeting%20Card%20Fundraiser" target="_blank" rel="noopener noreferrer">Contact us</a> for info on how to order from our large selection of greeting, holiday, occasion, and/or blank cards starting at $30/box of 30+ cards! Just under 50% of the proceeds go directly to our campaign.</p>
							<p>Sample of 4 cards with decorative envelopes from one selction below.</p>
							<p>You can also view this fundraiser on Facebook.</p>
							<img className="cardSample" src={cardSample} alt="Sample card selection"></img>
						</li>
						<li>
							<h3>Hold your own</h3>
							<p>Want to hold your own fundraiser? <a href="mailto:kenjohnstonwarriorfund@gmail.com?Subject=KJWF%20Contact%20Fundraiser%20Interest" target="_blank" rel="noopener noreferrer">Contact us!</a></p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Fundraisers