import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import fishing from '../../Images/ken/fishing.jpg';
import military from '../../Images/ken/military.jpg';
import police from '../../Images/ken/police.JPG';
import patient from '../../Images/ken/patient.JPG';
import surf from '../../Images/ken/surf-pic.JPG';
import mtn from '../../Images/ken/mtn-pic.JPG';

const Ken = () => {
	useEffect(() => {
		window.scrollTo(0,0)
	}, [])
	return (
		<div className='ken'>
			<div className='section-header'>
				<h2>Ken's Story</h2>
			</div>
			<img className='main-ken-pic' src={fishing} alt='Ken Johnston fishing' />
			<ul className='ken-timeline'>
				<li>
					<h3 className='ken-year'>
						2008
					</h3>
					<p>
						Ken Johnston had his first occurrence with a historically “rare” form of abdominal cancer, Peritoneal Carcinomatosis (i.e. Peritoneal Cancer), in his appendix. He was diagnosed with Peritoneal Carcinomatosis, PMP.
					</p>
					<p>
						He underwent emergency surgery to remove his appendix and after a seemingly simple recovery period, he carried on with life, excelling in advanced military training courses as Top Gun, and receiving the Director Training Award which exemplifies the professionalism of a Federal Law Enforcement Officer.
					</p>
				</li>
				<img className='ken-pic' src={military} alt='Ken Johnston active military' />
				<li>
					<h3 className='ken-year'>
						2008 - 2011
					</h3>
					<p>
						During this time Ken attended regular checkups to monitor his recovery and cancer markers. He did his best to carry on in true warrior spirit, determined to live a "normal" life.
					</p>
				</li>
				<img className='ken-pic' src={police} alt='Ken Johnston police' />
				<li>
					<h3 className='ken-year'>
						2011
					</h3>
					<p>
						After experiencing abdominal complications, Ken found out his cancer had returned in a very aggressive form, resulting in a necessary emergency surgery to remove the tumors that had formed. Fortunately, he was under the care of Wake Forest University’s Baptist Hospital, which was 1 of 3 hospitals in the U.S. and in 1 of 8 countries worldwide, containing a department specializing in this type of cancer. They conducted an advanced treatment technique called HIPEC in addition to cytoreductive (tumor removal) surgery. He also elected into a treatment that was used for research, in the hopes of furthering future treatment advancements.
					</p>
					<p>
						The 16-hour surgery resulted in the removal of his gallbladder, spleen, and the majority of his stomach, small and large intestines, in addition to abdominal wall scraping, making for a very extensive recovery period.
					</p>
				</li>
				<img className='ken-pic' src={patient} alt='Ken Johnston inpatient' />
				<li>
					<h3 className='ken-year'>
						2011 - 2013
					</h3>
					<p>
						During this time Ken attended regular checkups to monitor his recovery and cancer markers. His warrior spirit carried on as he fought through each day, despite everything he lived each day to the fullest by continuing to travel and trying new things such as learning to surf!
					</p>
				</li>
				<li>
					<h3 className='ken-year'>
						2013
					</h3>
					<p>
						Ken had his second HIPEC and cytoreductive surgery. More of his stomach and small and large intestines were removed.
					</p>
				</li>
				<li>
					<h3 className='ken-year'>
						2013 - 2016
					</h3>
					<p>
						During this time Ken attended regular checkups to monitor his recovery and cancer markers.
					</p>
				</li>
				<img className='ken-pic' src={surf} alt='Ken Johnston surfing' />
				<li>
					<h3 className='ken-year'>
						2016
					</h3>
					<p>
						Ken attempted a third HIPEC, however the third HIPEC was aborted as the surgery was too invasive for what his body could handle. After this point, his treatment was focused more on keeping him as comfortable as possible.
					</p>
				</li>
				<li>
					<h3 className='ken-year'>
						2016 - 2019
					</h3>
					<p>
						During this time, he experienced countless complications with diet, nutrition, physical strength, and organ function, to name a few.
					</p>
					<p>
						Despite the grim outlook he faced, Ken took on every day in a true warrior spirit. He rarely complained about his discomfort, lack of energy, or inability to eat most foods. When asked, he simply stated if he was "having a good day" or if it was just "alright".
					</p>
				</li>
				<img className='ken-pic' src={mtn} alt='Ken Johnston in mountains' />
				<li>
					<h3 className='ken-year'>
						May 2019
					</h3>
					<p>
						After 6 months of research and several attempts to find a way to help Ken and people like him, Ken's daughter, Brandi, asked for his blessing to establish a fund in his honor that would help advance research and development for people like him living with Peritoneal Carcinomatosis who aren't always given the most optimistic prognosis.
					</p>
					<p>
						He immediately agreed and was so appreciative that his story could potentially help, give hope, and provide a fighting warrior spirit to others in his position who might need it. Ultimately, he loved the idea of helping others who could benefit from hearing his story.
					</p>
				</li>
				<li>
					<h3 className='ken-year'>
						October 2019
					</h3>
					<p>
						Ken passed away. Although, his fighting warrior spirit lives on through us.
					</p>
					<p><a href="https://www.salemfh.com/obituaries/Kenneth-W-Johnston?obId=8293247" target="_blank" rel="noreferrer noopener">You can read his obituary here.</a></p>
				</li>
				<li>
					<h3 className='ken-year'>
						November 2019
					</h3>
					<p>
						November 13, 2019 would have been Ken's 59th birthday. In honor of him, the Ken Johnston Warrior Fund for Peritoneal Carcinomatosis Research was officially established and the 5-year countdown to raise $50,000 began. Read more about what this means by visiting our <Link to='/about'>About Us</Link>.
					</p>
				</li>
				<li>
				<h3 className='ken-year'>
						Today
					</h3>
					<p>
						Because of the passionate and advanced oncology department, medical professionals, and team at Wake Forest Baptist Health, patients suffering from this disease are gaining valuable time back with their families and loved ones. But we can do so much more.
					</p>
					<h4>
						Check out our <Link to='/get-involved'>ways to get involved</Link> and <Link to='/donate'>ways to donate</Link> to see how you can help!
					</h4>
					<h4>
						To learn more about Peritoneal Cancer and PMP, check out <Link to='/pmp'>About PMP</Link>.
					</h4>
				</li>
			</ul>
		</div>
	)
}

export default Ken