import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const GetInvolved = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	return (
		<div className='get-involved'>
			<div className='section-header'>
				<h2>Get Involved</h2>
			</div>
			<div className='get-involved-container'>
				<div className='get-involved-item'>
					<h3 className='get-involved-h3'>Donate</h3>
					<ul>
						<li><p><Link to='/donate'>Donate</Link> to our cause</p></li>
					</ul>
				</div>
				<div className='get-involved-item'>
					<h3 className='get-involved-h3'>Fundraise</h3>
					<ul>
						<li><p><a href="mailto:kenjohnstonwarriorfund@gmail.com?Subject=KJWF%20Contact%20Fundraiser%20Interest" target="_blank" rel="noopener noreferrer">Contact us</a> to hold your own fundraiser</p></li>
						<li><p>Participate in some of our <Link to='/fundraisers'>current fundraisers</Link></p></li>
					</ul>
				</div>
				<div className='get-involved-item'>
					<h3 className='get-involved-h3'>Advocate</h3>
					<ul>
						<li><p>Following us on social media</p></li>
						<li><p>Share our social media content with your personal network</p></li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default GetInvolved