import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import vfk1 from '../../Images/5k2021/5k-1.jpg';
import vfk2 from '../../Images/5k2021/5k-2.jpg';
import vfk3 from '../../Images/5k2021/5k-3.jpg';
import vfk4 from '../../Images/5k2021/5k-4.jpg';
import vfk5 from '../../Images/5k2021/5k-5.jpg';
import vfk6 from '../../Images/5k2021/5k-6.jpg';


const LandingPage = () => {
	useEffect(() => {
		window.scrollTo(0,0)
	}, [])
	return (
		<div className='landing-page'>
			<div className='landing-page-main'>
				<h1>Currently fundraising $50,000 to expedite the Ken Johnston Warrior Fund endowment</h1>
				<Link to='/about'>
					<button
						className='landing-page-button'
						id='lp-btn-1'
					>
						Read More
					</button>
				</Link>
				<Link to='/donate'>
					<button
						className='landing-page-button'
						id='lp-btn-2'
					>
						Donate
					</button>
				</Link>
			</div>
			{/* <meter value="28.25" min="0" max="100" />
			<p className='meter-value'>28%</p> */}
			<div className='landing-page-pic-container'>
				{/* <div className='landing-page-pic-item'>
					<h2>
						Join our 2021 2nd Annual Virtual 5K!
					</h2>
					<p>
						<i className="fas fa-chevron-circle-right" />
						<Link to="/donate">Sign up and please donate</Link> at least $5 to join.
					</p>
					<p>
						<i className="fas fa-chevron-circle-right" />
						Walk/hike/run any 5K (3.1 mi.) distance by <strong>Mon. May 31st</strong>.
					</p>
					<p>
						<i className="fas fa-chevron-circle-right" />
						<a href="mailto:kenjohnstonwarriorfund@gmail.com?Subject=Virtual%205K%20Entry" target="_blank" rel="noopener noreferrer">Submit</a> a photo of your time and distance.
					</p>
					<p>
						<i className="fas fa-chevron-circle-right" />
						Be sure to <a href="https://www.instagram.com/kenjohnstonwarriorfund" target="_blank" rel="noopener noreferrer">tag us</a> in your instagram post!
					</p>
					<small><i>*100% of donations go to the Ken Johnston Warrior Fund.</i></small>
				</div> */}
				<div className='landing-page-pic-item' id='lp-pic-1'>
					<h2>
						Thank you to everyone who participated in our 2nd Annual 5K
					</h2>
					<h2>Together, we raised $1,770!</h2>
					<div className="virtual-5k-container">
						<img className="vfk-pic" src={vfk1} alt="virtual 5k" />
						<img className="vfk-pic" src={vfk2} alt="virtual 5k" />
						<img className="vfk-pic" src={vfk3} alt="virtual 5k" />
						<img className="vfk-pic" src={vfk4} alt="virtual 5k" />
						<img className="vfk-pic" src={vfk5} alt="virtual 5k" />
						<img className="vfk-pic" src={vfk6} alt="virtual 5k" />
					</div>
				</div>
				{/* <div className="lp-second-container">
					<div className='landing-page-pic-item' id='lp-pic-2'>
						<h2>
						<Link to="/fundraisers">Fundraisers   <i className="fas fa-chevron-right"></i></Link>
						</h2>
					</div>
					<div className='landing-page-pic-item' id='lp-pic-3'>
						<h2>
							<Link to="/get-involved">Help spread the word   <i className="fas fa-chevron-right"></i></Link>
						</h2>
					</div>
				</div> */}
			</div>
		</div>
	);
}

export default LandingPage