import React from 'react';

const Footer = () => {
	return (
		<div className='footer'>
			<div className='footer-container'>
				<div className='links'>
					<p><a href="mailto:kenjohnstonwarriorfund@gmail.com?Subject=KJWF%20Contact" target="_blank" rel="noopener noreferrer"><i
						className="far fa-envelope"></i></a></p>
					<p><a href="https://www.linkedin.com/company/ken-johnston-warrior-fund" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></p>
					<p><a href="https://www.instagram.com/kenjohnstonwarriorfund" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></p>
				</div>
				<p>&#169; { new Date().getFullYear() } Ken Johnston Warrior Fund</p>
			</div>
		</div>
	);
}

export default Footer