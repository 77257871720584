import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Images/KJWF-full-logo-small-no-bg.png';

const Nav = () => {
	return(
		<div className='nav'>
			<div className='nav-left'>
				<NavLink
					exact to='/'
					className='nav-logo'>
						<img className='logo' src={logo} alt="KJWF logo"/>
				</NavLink>
			</div>
			<div className='nav-right'>
				<NavLink
					exact to='/ken'
					className='nav-link'>
						Ken's Story
				</NavLink>
				<NavLink
					exact to='/about'
					className='nav-link'>
						About Us
				</NavLink>
				<NavLink
					exact to='/pmp'
					className='nav-link'>
						PMP
				</NavLink>
				{/* <NavLink
					exact to='/veterans'
					className='nav-link'>
						Veterans
				</NavLink> */}
				{/* <NavLink
					exact to='/store'
					className='nav-link'>
						Store
				</NavLink> */}
				{/* <NavLink
					exact to='/press'
					className='nav-link'>
						Press
				</NavLink> */}
				{/* <NavLink
					exact to='/get-involved'
					className='nav-link get-involved'>
						Get Involved
				</NavLink> */}
				<NavLink
					exact to='/donate'
					className='nav-link donate'
					id='nav-link donate'>
						<button className='nav-button'>Donate</button>
				</NavLink>
			</div>
			<div className="hamburger">
				<button className="dropbtn">
					<i className="fas fa-bars"></i>
				</button>
				<div className="dropdown">
					<NavLink to="/ken">Ken's Story</NavLink>
					<NavLink to="/about">About Us</NavLink>
					<NavLink to="/pmp">PMP</NavLink>
					{/* <NavLink to="/get-involved">Get Involved</NavLink> */}
					<NavLink to="/donate">Donate</NavLink>
				</div>
			</div>
		</div>
	);
}

export default Nav